import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const Image = () => {
	const data = useStaticQuery(
		graphql`
			query {
				image: file(
					relativePath: { eq: "bid-leveling/compare-bids/img/us/image.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 2000) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fluid={data.image.childImageSharp.fluid}
			alt="Centralized Image"
		/>
	)
}

export default Image
