// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Hero } from 'views/components'
import H1 from 'components/h1'
import { breakpoints, tablet, mobile } from 'shared/media-queries'
import HeaderBackgroundImage from './background-image'
import { lightBlue } from 'shared/colors'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.laptop) {
		return '555px'
	} else if (screenWidth >= breakpoints.tablet) {
		return '500px'
	}
	return '100%'
}

const heading = title => (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '44px' },
		}}
	>
		{title}
	</H1>
)

const subHeading = description => (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[tablet]: { fontSize: '20px' },
			[mobile]: { fontSize: '18px' },
		}}
	>
		{description}
	</Div>
)

const Header = ({ screenWidth, description, title }) => (
	<Hero
		backgroundImage={<HeaderBackgroundImage screenWidth={screenWidth} />}
		heading={heading(title)}
		subHeading={subHeading(description)}
		wordingWidth={getWordingWidth(screenWidth)}
		videoId="li89lenir7"
		ctaColor={lightBlue}
		demoSource="Bid Leveling Campaign"
	/>
)

export default Header
