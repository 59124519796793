// @flow
import * as React from 'react'

import Layout from 'components/layout'
import {
	SectionSpacer,
	ContentWrapper,
	SectionBreak,
	Footer,
} from 'views/components'
import Header from './header'
import CompareBids from './compare-bids'
import CentralizedBidLeveling from './centralized-bid-leveling'
import ReduceRisk from './reduce-risk'
import QuoteSection from './quote-section'
import ResourceHub from './resource-hub'
import { lightBlue } from 'shared/colors'
import useScreenWidth from 'hooks/use-screen-width'

import withCountry, { isUSA } from 'shared/get-country'

import generateContent from './content'

const Leveling = ({ country }) => {
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}
	const content = generateContent(country)
	if (!country) return null
	const { header, compare, centralized, resourceHub } = content

	return (
		<Layout
			title="Bid Leveling - Accurate Bid Comparisons | BuildingConnected"
			description="Our Bid Leveling tool helps general contractors accurately compare bids and identify the best bid faster than ever before."
		>
			<Header screenWidth={screenWidth} {...header} />
			<SectionSpacer />
			<ContentWrapper>
				<CompareBids screenWidth={screenWidth} {...compare} country={country} />
				<SectionBreak />
				<CentralizedBidLeveling
					screenWidth={screenWidth}
					{...centralized}
					country={country}
				/>
				<SectionBreak />
				{isUSA(country) && <ReduceRisk screenWidth={screenWidth} />}
				{isUSA(country) && <SectionBreak />}
				<QuoteSection />
				<SectionBreak />
				<ResourceHub content={resourceHub} />
			</ContentWrapper>
			<SectionSpacer />
			<Footer
				wording="Try BuildingConnected Pro with your team for free."
				wordingMaxWidth="666px"
				ctaColor={lightBlue}
				demoSource="Bid Leveling Campaign"
			/>
		</Layout>
	)
}

export default withCountry(Leveling)
