import * as React from 'react'
import { countryCodes } from 'shared/get-country'

import { default as Image } from './us'
import { default as ImageAnz } from './anz'
import { default as ImageUk } from './uk'
import { default as ImageIe } from './ie'

const images = {
	[countryCodes.us]: <Image />,
	[countryCodes.nz]: <ImageAnz />,
	[countryCodes.au]: <ImageAnz />,
	[countryCodes.uk]: <ImageUk />,
	[countryCodes.ie]: <ImageIe />,
}

const constants = ({ country }) => images[country]
export default constants
