// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import { ResourceHub } from 'views/components'
import videoImage from './img/video.png'
import videoImage2x from './img/video@2x.png'
import videoImage3x from './img/video@3x.png'

const cards = content => [
	{
		image: (
			<Img
				width="100%"
				src={videoImage}
				srcSet={`${videoImage2x} 2x, ${videoImage3x} 3x`}
			/>
		),
		title: 'Video',
		wording: content.cards.card1.wording,
		cta: 'Watch the video',
		href: content.cards.card1.link,
	},
	{
		image: <ResourceHub.DefaultBlueImage />,
		title: 'Customers',
		wording: 'Customer Success and their stories.',
		cta: 'Read more',
		href: 'https://blog.buildingconnected.com/category/customer-stories/',
	},
	{
		image: <ResourceHub.DefaultGreenImage />,
		title: 'How to',
		wording: content.cards.card3.wording,
		cta: 'Go to help center',
		href: content.cards.card3.link,
	},
]

const ResourceHubSection = ({ content }) => (
	<ResourceHub
		title="The resource hub."
		subtitle={content.subtitle}
		cards={cards(content)}
	/>
)

export default ResourceHubSection
