import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const Image = () => {
	const data = useStaticQuery(
		graphql`
			query {
				image: file(
					relativePath: {
						eq: "bid-leveling/centralized-bid-leveling/img/ie/image.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 2000) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fluid={data.image.childImageSharp.fluid}
			alt="Centralised Image ie"
		/>
	)
}

export default Image
