import { countryCodes } from 'shared/get-country'

const usCopy = {
	header: {
		title: 'Bid Leveling',
		description:
			'Get out of Excel and level your bids faster. Identify the best bid accurately and efficiently, from one place.',
	},
	compare: {
		subtitle: 'Compare bids side-by-side with pinpoint precision.',
		titleAndInfoItems: [
			{
				title: 'Find the lowest bidder on the fly',
				wording: `Plug values to automatically adjust totals and identify the best bid accurately and efficiently.`,
			},
			{
				title: 'Automatically change scope',
				wording: `Getting new information after you’ve already received bids? No problem—easily edit scope sheets in Bid Leveling.`,
			},
			{
				title: 'Add last-minute bids, quickly',
				wording: `Easily include unsolicited bids while you level for quick comparison.`,
			},
		],
	},
	centralized: {
		subtitle: 'Centralized bid leveling for maximum collaboration.',
		titleAndInfoItems: [
			{
				title: 'Easily access bid versions',
				wording: `Stop juggling all those Excel files. Easily revisit original proposals and values.`,
			},
			{
				title: 'Validate awarded bids',
				wording: `Export bids to Excel and show your owner a comprehensive overview of why you chose a specific bidder.`,
			},
			{
				title: 'Increase visibility and collaboration',
				wording: `Leave notes for yourself and in shared projects to keep your entire team on the same page.`,
			},
		],
	},
	resourceHub: {
		subtitle: 'All the information you need to make Bid Leveling work for you.',
		cards: {
			card1: {
				wording: 'See how Bid Leveling will save you a ton of time.',
				link:
					'https://www.buildingconnected.com/bc-pro/resource-hub?wchannelid=dflcwshvmw&wvideoid=fv3uejg15c',
			},
			card3: {
				wording: 'Learn how to use Bid Leveling.',
				link:
					'https://buildingconnected.zendesk.com/hc/en-us/articles/360010340914-How-to-access-Bid-Leveling-',
			},
		},
	},
}

const anzCopy = {
	header: {
		title: 'Tender comparison',
		description:
			'Identify the best tender accurately and efficiently, from one place.',
	},
	compare: {
		subtitle: 'Compare tenders side-by-side with pinpoint precision.',
		titleAndInfoItems: [
			{
				title: 'Find the lowest tender on the fly',
				wording: `Edit values to automatically adjust totals and identify the best tender accurately and efficiently.`,
				bullets: [
					'Add inclusions, exclusions, and alternates',
					'Make dynamic adjustments',
				],
			},
			{
				title: 'Automatically change scope',
				wording: `Getting new information after you’ve already received tenders? No problem— easily make adjustments and edit line items while comparing tenders.`,
			},
			{
				title: 'Add last-minute tenders, quickly',
				wording: `Easily include new unsolicited tenders for quick comparison.`,
			},
		],
	},
	centralized: {
		subtitle: 'Centralised tender comparison for maximum collaboration.',
		titleAndInfoItems: [
			{
				title: 'Easily access tender versions',
				wording: `Get notified about new tender revisions. Revisit original proposals and review revisions to identify changes.`,
			},
			{
				title: 'Increase visibility and collaboration',
				wording: `Leave notes, color specific cells, or include private attachments to keep your entire team on the same page.`,
			},
			{
				title: 'Validate awarded tenders',
				wording: `Export tenders to Excel and show your client a comprehensive overview of why you chose a specific tenderer.`,
			},
		],
	},
	resourceHub: {
		subtitle:
			'All the information you need to make Tender Comparison work for you.',
		cards: {
			card1: {
				wording: 'See how Tender Comparison will save you a ton of time.',
				link:
					'https://www.buildingconnected.com/bc-pro/resource-hub?wchannelid=dflcwshvmw&wvideoid=fv3uejg15c',
			},
			card3: {
				wording: 'Learn how to use Tender Comparison.',
				link:
					'https://buildingconnected.zendesk.com/hc/en-au/articles/360010340914-How-to-access-Bid-Leveling',
			},
		},
	},
}

const ukCopy = {
	...anzCopy,
	resourceHub: {
		...anzCopy.resourceHub,
		cards: {
			...anzCopy.resourceHub.cards,
			card3: {
				wording: 'Learn how to use Tender Comparison.',
				link:
					'https://buildingconnected.zendesk.com/hc/en-uk/articles/360010340914-How-to-access-Tender-Comparison',
			},
		},
	},
}
const ieCopy = {
	...anzCopy,
	resourceHub: {
		...anzCopy.resourceHub,
		cards: {
			...anzCopy.resourceHub.cards,
			card3: {
				wording: 'Learn how to use Tender Comparison.',
				link:
					'https://buildingconnected.zendesk.com/hc/en-ie/articles/360010340914-How-to-access-Tender-Comparison',
			},
		},
	},
}

const content = country => {
	switch (country) {
		case countryCodes.au:
		case countryCodes.nz:
			return anzCopy
		case countryCodes.ie:
			return ieCopy
		case countryCodes.uk:
			return ukCopy
		default:
			return usCopy
	}
}

export default content
