// @flow
import * as React from 'react'
import { InfoAndImage } from 'views/components'
import Image from './img'
import { breakpoints } from 'shared/media-queries'

const CompareBids = ({
	screenWidth,
	subtitle,
	titleAndInfoItems,
	bulletItems,
	country,
}) => (
	<InfoAndImage
		image={<Image country={country} />}
		imageOnRight
		subtitle={subtitle}
		info="&nbsp;"
		centered={screenWidth < breakpoints.laptop}
		titleAndInfoItems={titleAndInfoItems}
		bulletItems={bulletItems}
	/>
)

export default CompareBids
