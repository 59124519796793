// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import background1440 from './img/1440.jpg'
import background14402x from './img/1440@2x.jpg'
import background14403x from './img/1440@3x.jpg'
import background1024 from './img/1024.jpg'
import background10242x from './img/1024@2x.jpg'
import background10243x from './img/1024@3x.jpg'
import background768 from './img/768.jpg'
import background7682x from './img/768@2x.jpg'
import background7683x from './img/768@3x.jpg'
import background320 from './img/320.jpg'
import background3202x from './img/320@2x.jpg'
import background3203x from './img/320@3x.jpg'
import { breakpoints } from 'shared/media-queries'

const image1440 = (
	<Img
		src={background1440}
		srcSet={`${background14402x} 2x, ${background14403x} 3x`}
	/>
)
const image1024 = (
	<Img
		src={background1024}
		srcSet={`${background10242x} 2x, ${background10243x} 3x`}
	/>
)
const image768 = (
	<Img
		src={background768}
		srcSet={`${background7682x} 2x, ${background7683x} 3x`}
	/>
)
const image320 = (
	<Img
		src={background320}
		srcSet={`${background3202x} 2x, ${background3203x} 3x`}
	/>
)

const HeaderBackgroundImage = ({ screenWidth }: { screenWidth: number }) => {
	if (screenWidth > breakpoints.laptop) {
		return image1440
	} else if (screenWidth > breakpoints.tablet) {
		return image1024
	} else if (screenWidth > breakpoints.mobile) {
		return image768
	} else if (screenWidth >= 0) {
		return image320
	}
	return image1440 // Default to largest in case something weird is going on
}

export default HeaderBackgroundImage
