// @flow
import * as React from 'react'

import { LargeQuote } from 'views/components'
import justin from './img/justin.png'
import justin2x from './img/justin@2x.png'
import justin3x from './img/justin@3x.png'

const QuoteSection = () => (
	<LargeQuote
		image1x={justin}
		image2x={justin2x}
		image3x={justin3x}
		quote="The ability to solicit, level, and collaborate on trade partners’ proposals will set the bar for the next level of preconstruction processes."
		name="JUSTIN E. BRODNAX, HOAR CONSTRUCTION"
	/>
)

export default QuoteSection
