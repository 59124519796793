// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import { InfoAndImage } from 'views/components'
import image from './img/image.png'
import image2x from './img/image@2x.png'
import image3x from './img/image@3x.png'
import { breakpoints, laptop } from 'shared/media-queries'

const ReduceRisk = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		image={
			<Img
				src={image}
				srcSet={`${image2x} 2x, ${image3x} 3x`}
				css={{
					[laptop]: { width: '100%' },
				}}
			/>
		}
		imageOnRight
		wordingWidth="405px"
		subtitle="Reduce risk upfront."
		info="Instantly know which subs are qualified before awarding them work. Check their project limit, EMR and more."
		centered={screenWidth < breakpoints.laptop}
	/>
)

export default ReduceRisk
